import * as React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
    Toolbar,
    Tooltip,
    Chip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import "../style.css";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function fixHeader(qcMessages, qcData) {
    var uniqueQcMessages = Array.from(new Set(qcMessages));

    if (uniqueQcMessages.length > 0) {
        return (
            <div>
                <Typography variant="h6">Quality controls failed:</Typography>
                <ul>
                    {uniqueQcMessages.map((m, index) => (
                        <li key={index}>
                            <Typography variant="h6">{m}</Typography>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    if (qcData.some((s) => s.result === "DISQUALIFIED")) {
        return (
            <Typography variant="h6">
                Quality controls failed. See below.
            </Typography>
        );
    }
    return <Typography variant="h6">Quality controls passed</Typography>;
}

const headCells = [
    {
        id: "name",
        label: "Control sample",
    },
    {
        id: "result",
        label: "Plate QC",
    },
    {
        id: "message",
        label: "Message",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function QcTable({ rows, header }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                {header && fixHeader(header, rows)}
            </Toolbar>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow key={row.name}>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={row.resultMessage}>
                                                {row.result === "POSITIVE" ||
                                                row.result === "NEGATIVE" ? (
                                                    <Chip
                                                        color="success"
                                                        icon={<DoneIcon />}
                                                        label="Pass"
                                                    />
                                                ) : (
                                                    <Chip
                                                        color="error"
                                                        icon={<WarningIcon />}
                                                        label={
                                                            row.message.length >
                                                            0
                                                                ? "Fail"
                                                                : "Disqualified"
                                                        }
                                                    />
                                                )}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.message.length > 0 ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        maxWidth: "400px",
                                                    }}
                                                >
                                                    <WarningIcon
                                                        color="error"
                                                        sx={{
                                                            paddingRight:
                                                                "10px",
                                                        }}
                                                    />
                                                    <ul>
                                                        {[
                                                            ...new Set(
                                                                row.message
                                                            ),
                                                        ].join(", ")}
                                                    </ul>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export { QcTable };
