import React from "react";
import { DropdownPicker } from "./DropdownPicker";
import { Box, Button, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import "../style.css";

function FileForm({
    kitlots,
    selectedKitlot,
    setSelectedKitlot,
    setSelectedFile,
    fileName,
    setFileName,
    onSubmitTableData,
    disabled,
    helpText,
}) {
    const handleKitlotPick = (e, value) => {
        setSelectedKitlot(value);
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setFileName(file.name);
    };

    return (
        <div>
            <form className="form">
                <Typography className="basicpadding">
                    Select GA-map® reagent kit lot
                </Typography>
                <DropdownPicker
                    value={selectedKitlot}
                    options={kitlots
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .reverse()}
                    handleChange={handleKitlotPick}
                    disabled={disabled}
                    label="Kit lots"
                />
                <div className="fileupload">
                    <Box
                        component="div"
                        sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            color: (theme) => theme.palette.ga.gaText,
                        }}
                    >
                        {fileName}
                    </Box>
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<UploadIcon />}
                        className="button"
                        disabled={disabled}
                    >
                        Upload CSV File
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileInput}
                            onClick={(e) => (e.target.value = null)}
                            hidden
                        />
                    </Button>
                </div>
            </form>
            {!disabled && (
                <div className="button-error">
                    <Button
                        onClick={onSubmitTableData}
                        variant="contained"
                        sx={{ margin: "15px 0" }}
                        className="button"
                    >
                        Get Result
                    </Button>
                    <Typography color="red" style={{ margin: "10px" }}>
                        {helpText}
                    </Typography>
                </div>
            )}
        </div>
    );
}

export { FileForm };
