import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
    Button,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Checkbox,
    Tooltip,
    Chip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import "../style.css";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        label: "Sample name",
    },
    {
        id: "result",
        label: "Result",
    },
    {
        id: "qc",
        label: "Sample QC",
    },
    {
        id: "message",
        label: "Message",
    },
];

function GetChip(result, resultMessage) {
    if (result === "POSITIVE") {
        return (
            <Tooltip title={resultMessage}>
                <Chip color="warning" label="Dysbiotic" />
            </Tooltip>
        );
    }
    if (result === "NEGATIVE") {
        return (
            <Tooltip title={resultMessage}>
                <Chip color="success" label="Normobiotic" />
            </Tooltip>
        );
    }
    return "-";
}

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ selected, fullXmlHandle, singleXmlHandle }) {
    const numSelected = selected.length;

    const singleXmlSubmitter = () => {
        singleXmlHandle(selected);
    };

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 50%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} sample(s) selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: "1 1 50%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Samples
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Download one XML file for each selected sample">
                    <Button
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                        onClick={singleXmlSubmitter}
                        className="button"
                    >
                        XML files for selected samples
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip title="Download full XML file, containing all samples">
                    <Button
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                        onClick={fullXmlHandle}
                        className="button"
                    >
                        Full XML file
                    </Button>
                </Tooltip>
            )}
        </Toolbar>
    );
}

function SampleTable({ rows, fullXmlHandle, singleXmlHandle }) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <div>
            <EnhancedTableToolbar
                selected={selected}
                fullXmlHandle={fullXmlHandle}
                singleXmlHandle={singleXmlHandle}
            />
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium"
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row.name)
                                        }
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    "aria-labelledby": labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {GetChip(
                                                row.result,
                                                row.resultMessage
                                            )}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={row.resultMessage}>
                                                {row.result === "POSITIVE" ||
                                                row.result === "NEGATIVE" ? (
                                                    <Chip
                                                        color="success"
                                                        icon={<DoneIcon />}
                                                        label="Pass"
                                                    />
                                                ) : (
                                                    <Chip
                                                        color="error"
                                                        icon={<WarningIcon />}
                                                        label={
                                                            row.resultMessage ===
                                                            "The GA-map® disqualifies the entire run"
                                                                ? "Disqualified"
                                                                : "Fail"
                                                        }
                                                    />
                                                )}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.message.length > 0 ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        maxWidth: "400px",
                                                    }}
                                                >
                                                    <WarningIcon
                                                        color="error"
                                                        sx={{
                                                            paddingRight:
                                                                "10px",
                                                        }}
                                                    />
                                                    {[
                                                        ...new Set(row.message),
                                                    ].join(", ")}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export { SampleTable };
