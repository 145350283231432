import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Paper } from "@mui/material";
import { SampleTable } from "./SampleTable";
import { QcTable } from "./QcTable";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function ResultTabs({
    tableData,
    qcData,
    qcMessages,
    fullXmlHandle,
    singleXmlHandle,
}) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper elevation={8}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Sample Result"
                            id="tab-result"
                            className="tab"
                        />
                        <Tab
                            label="Quality Controls"
                            id="tab-qc"
                            className="tab"
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <SampleTable
                        rows={tableData}
                        fullXmlHandle={fullXmlHandle}
                        singleXmlHandle={singleXmlHandle}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <QcTable rows={qcData} header={qcMessages} />
                </TabPanel>
            </Box>
        </Paper>
    );
}

export { ResultTabs };
