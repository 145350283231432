import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function DropdownPicker({ value, options, handleChange, disabled, label }) {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            disabled={disabled}
            value={value}
            options={options}
            onChange={handleChange}
            fullWidth={true}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
}

export { DropdownPicker };
