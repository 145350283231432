import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import { HeaderBar } from "./components/HeaderBar";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import theme from "./theme/Theme";
import { Contact } from "./Pages/Contact";
import { Admin } from "./Pages/Admin";

function getToken(getAccessTokenSilently) {
    return getAccessTokenSilently({
        audience: `https://dysbiosis-api/`,
        ignoreCache: true,
        scope: "offline_access",
    }).then((receivedToken) => {
        return receivedToken;
    });
}

function App() {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState();

    useEffect(() => {
        async function wrapGetToken() {
            const fetchedToken = await getToken(getAccessTokenSilently);
            setToken(fetchedToken);
        }
        wrapGetToken();
    }, [getAccessTokenSilently]);

    return (
        <ThemeProvider theme={theme}>
            <div className="app">
                <HeaderBar />
                <div className="page">
                    <Routes>
                        <Route path="/" element={<Home token={token} />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route
                            path="/admin_kitlot_update"
                            element={<Admin token={token} />}
                        />
                    </Routes>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default App;
