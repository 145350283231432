import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

var fontSize = 14; // 14 = 1rem = 16px / 15.75 = 1.125rem = 18px
var fontSizeNormal = "1rem";
var fontSizeLarge = "1.5rem";
var fontSizeSmall = "0.9rem";
var fontSizeH1 = "3.0rem";
var fontSizeH2 = "2.3rem";
var fontSizeH3 = "1.7rem";
var fontSizeH4 = "1.25rem";
var fontSizeH5 = "1.25rem";
var fontSizeH6 = "1rem";

var fontWeightLight = 300;
var fontWeightRegular = 400;
var fontWeightMedium = 500;
var fontWeightBold = 600;

var ga50 = "#F5FCFF";
var ga100 = "#D4EDF9";
var ga200 = "#A4D0E7";
var ga300 = "#6AB5DD";
var ga400 = "#419BCC";
var ga500 = "#007EC1";
var ga600 = "#076292";
var ga700 = "#014C74";
var ga800 = "#043148";
var ga900 = "#032333";
var gaText = "#475467";

const theme = createTheme({
    palette: {
        primary: {
            main: ga700,
            dark: ga900,
        },
        secondary: {
            main: ga400,
        },
        ga: {
            ga50: ga50,
            ga100: ga100,
            ga200: ga200,
            ga300: ga300,
            ga400: ga400,
            ga500: ga500,
            ga600: ga600,
            ga700: ga700,
            ga800: ga800,
            ga900: ga900,
            gaText: gaText,
        },
    },
    typography: {
        // General styling
        fontSize: fontSize,
        fontSizeNormal: fontSizeNormal,
        fontFamily: `"Inter", sans-serif`,
        fontWeightLight: fontWeightLight,
        fontWeightRegular: fontWeightRegular,
        fontWeightMedium: fontWeightMedium,
        fontWeightBold: fontWeightBold,
        // Style paragraphs by classname
        body1: {
            color: gaText,
            "&.largeText": {
                color: gaText,
                fontSize: fontSizeLarge,
            },
            "&.smallText": {
                fontSize: fontSizeSmall,
            },
            "&.patientInfoText": {
                marginBottom: "1rem",
            },
            "&.notice": {
                color: red,
                fontWeight: fontWeightMedium,
            },
            "&.menu-add-lab-result-p": {
                marginBottom: "8px",
            },
            "&.menu-add-lab-result-no-diagnosis": {
                marginTop: "16px",
            },
        },
        //Button
        button: {
            fontSize: fontSizeNormal,
            textTransform: "none",
        },
        // Headers
        h1: {
            color: gaText,
            fontSize: fontSizeH1,
            marginBlockStart: "1.5rem",
            marginBlockEnd: "1.2rem",
            fontWeight: fontWeightRegular,
        },
        h2: {
            color: gaText,
            fontSize: fontSizeH2,
            marginBlockStart: "0.83rem",
            marginBlockEnd: "0.83rem",
            fontWeight: fontWeightRegular,
        },
        h3: {
            color: gaText,
            fontSize: fontSizeH3,
            marginBlockStart: "1rem",
            marginBlockEnd: "1rem",
            fontWeight: fontWeightRegular,
        },
        h4: {
            color: gaText,
            fontSize: fontSizeH4,
            marginBlockStart: "0.5rem",
            marginBlockEnd: "0.5rem",
            fontWeight: fontWeightRegular,
        },
        h5: {
            color: gaText,
            fontSize: fontSizeH5,
            marginBlockStart: "0.2rem",
            marginBlockEnd: "0.2rem",
            fontWeight: fontWeightMedium,
        },
        h6: {
            color: gaText,
            fontSize: fontSizeH6,
            marginBlockStart: "0.2rem",
            marginBlockEnd: "0.2rem",
            fontWeight: fontWeightMedium,
        },
    },
});

export default theme;
