import React, { useState } from "react";
import "./../App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Paper, Box, Avatar, Typography } from "@mui/material";
import { SnackbarAlert } from "../components/SnackbarAlert";
import "../style.css";

function Profile() {
    const { logout, user } = useAuth0();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState({
        severity: "info",
        message: "",
    });

    function sendMail() {
        fetch(
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                    email: user.email,
                    connection: "Username-Password-Authentication",
                }),
            }
        )
            .then((response) => {
                if (response.ok) {
                    response.text().then((responseData) => {
                        setAlertContent({
                            severity: "success",
                            message: responseData,
                        });
                        setAlertOpen(true);
                    });
                } else {
                    setAlertContent({
                        severity: "warning",
                        message:
                            "Something went wrong. Try again later or contact support!",
                    });
                    setAlertOpen(true);
                }
            })
            .catch((error) => {
                console.log("error:: ");
                console.log(error);
            });
    }

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertOpen(false);
    };

    return (
        <div className="flexbox-page">
            <Paper elevation={8} className="profile-paper">
                <Box className="profile-box">
                    <Avatar src={user.picture} sx={{ width: 90, height: 90 }} />
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{ margin: "15px 0 0" }}
                    >
                        {user.name}
                    </Typography>
                    <Typography variant="body1" sx={{ margin: "15px 0" }}>
                        <i>{user.email}</i>
                    </Typography>
                    <Button
                        onClick={() => sendMail()}
                        variant="contained"
                        color="secondary"
                        fullWidth={true}
                        className="button"
                    >
                        Reset Password
                    </Button>
                    <Button
                        onClick={() => logout()}
                        variant="contained"
                        fullWidth={true}
                        className="button"
                        sx={{ margin: "10px 0" }}
                    >
                        Log out
                    </Button>
                </Box>
            </Paper>
            <SnackbarAlert
                open={alertOpen}
                severity={alertContent.severity}
                message={alertContent.message}
                handleClose={handleAlertClose}
            />
        </div>
    );
}

export default Profile;
