import React from "react";
import { Alert, Snackbar, Typography } from "@mui/material";

function SnackbarAlert({ open, message, severity, handleClose }) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={severity === "success" ? 6000 : 10000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "500px" }}
            >
                <Typography>{message}</Typography>
            </Alert>
        </Snackbar>
    );
}

export { SnackbarAlert };
