import React, { useState, useCallback } from "react";
import { Button, Typography, Alert, CircularProgress } from "@mui/material";
import { SnackbarAlert } from "../components/SnackbarAlert";
import { ResultTabs } from "../components/ResultTabs";
import { FileForm } from "../components/FileForm";
import "../style.css";
import "./../App.css";
import { MachineSelect } from "../components/MachineSelect";

function Home({ token }) {
    const URIs = [
        process.env.REACT_APP_API1_URI,
        process.env.REACT_APP_API2_URI,
        process.env.REACT_APP_API3_URI,
        process.env.REACT_APP_API4_URI,
        process.env.REACT_APP_API5_URI,
        process.env.REACT_APP_API6_URI,
    ];
    const [step, setStep] = useState(1);
    const [alert, setAlert] = useState(false);
    const [kitlots, setKitlots] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedKitlot, setSelectedKitlot] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState("No file selected");
    const [tableData, setTableData] = useState([]);
    const [qcData, setQcData] = useState([]);
    const [qcMessages, setQcMessages] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContent, setAlertContent] = useState({
        severity: "info",
        message: "",
    });
    const [helpText, setHelpText] = useState("");

    function reset() {
        setStep(1);
        setAlert(false);
        setSelectedKitlot(null);
        setKitlots([]);
        setSelectedMachine(null);
        setSelectedFile(null);
        setQcData([]);
        setFileName("No file selected");
        setTableData([]);
    }

    function fileFail() {
        setSelectedKitlot(null);
        setSelectedFile(null);
        setFileName("No file selected");
    }

    const onSubmitMachineSelected = () => {
        if (selectedMachine && token) {
            setStep(2);
            fetchKitlots().then((kits) => {
                if (kits.length > 0) {
                    if (!hasDup(kits)) {
                        setKitlots(kits);
                    } else {
                        setAlert(true);
                    }
                } else {
                    setAlert(true);
                }
            });
        }
    };

    async function fetchKitlots() {
        try {
            var kits = [];
            for (let i = 0; i < URIs.length; i++) {
                try {
                    const machine = (await fetchMachineType(URIs[i])) ?? "";
                    if (machine == selectedMachine.id) {
                        const kit = await fetchKitFromOne(URIs[i]);
                        if (kit) {
                            kits = [...kits, ...kit];
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            return kits;
        } catch (e) {
            console.log(e);
        }
    }

    async function fetchMachineType(uri) {
        try {
            return fetch(`${uri}/getMachine`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    return "";
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    const fetchKitFromOne = useCallback(
        async (uri) => {
            try {
                const response = await fetch(`${uri}/getKitlots`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const responseData = await response.json();
                    return responseData.map((t) => {
                        return { label: t, id: t, uri: uri };
                    });
                }
            } catch (e) {
                console.log(e);
            }
            return null;
        },
        [token]
    );

    function hasDup(kits) {
        const ids = kits.map((t) => {
            return t.id;
        });
        return new Set(ids).size !== ids.length;
    }

    const onSubmitTableData = () => {
        if (selectedFile && selectedKitlot) {
            setHelpText("");
            const formData = new FormData();
            formData.append("file", selectedFile);

            fetch(
                `${selectedKitlot.uri}/runAnalysisGetTable?lotname=${selectedKitlot.id}&machineType=${selectedMachine.id}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((responseData) => {
                            var sampleList = responseData["Samples"];
                            var samples = [];
                            var qc = [];
                            for (var i = 0; i < sampleList.length; i++) {
                                var message = [];
                                if (
                                    sampleList[i]["QcValueErrorMessage"]
                                        .length > 0
                                ) {
                                    message =
                                        sampleList[i]["QcValueErrorMessage"];
                                }
                                if (
                                    sampleList[i]["Name"].substring(0, 3) ===
                                    "QCC"
                                ) {
                                    qc.push({
                                        name: sampleList[i]["Name"],
                                        result: sampleList[i]["Result"],
                                        message: message,
                                    });
                                } else {
                                    samples.push({
                                        name: sampleList[i]["Name"],
                                        result: sampleList[i]["Result"],
                                        resultMessage:
                                            sampleList[i]["ResultMessage"],
                                        message: message,
                                    });
                                }
                            }
                            setTableData(samples);
                            setQcData(qc);
                            if (responseData["QcRunErrorMessage"]) {
                                setQcMessages(
                                    responseData["QcRunErrorMessage"]
                                );
                            }
                            setAlertOpen(false);
                        });
                    }
                    if (response.status === 400) {
                        response.text().then((responseData) => {
                            setAlertContent({
                                severity: "error",
                                message: `Error in file: ${responseData}`,
                            });
                            setAlertOpen(true);
                            fileFail();
                        });
                    }
                    if (response.status === 500) {
                        setAlertContent({
                            severity: "error",
                            message:
                                "Unknown system error! Please contact support.",
                        });
                        setAlertOpen(true);
                        fileFail();
                    }
                })
                .catch((error) => {
                    console.log("error:: ");
                    console.log(error);
                });
        } else if (!selectedKitlot) {
            setHelpText("Please select reagent kit lot");
        } else {
            setHelpText("Please upload a CSV file");
        }
    };

    const onSubmitXml = () => {
        const formData = new FormData();
        formData.append("file", selectedFile);

        fetch(
            `${selectedKitlot.uri}/toFullXml?lotname=${selectedKitlot.id}&machineType=${selectedMachine.id}`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }
        )
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `Result-${fileName}.xml`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setAlertContent({
                    severity: "success",
                    message: `Success! Result-${fileName}.xml is downloading...`,
                });
                setAlertOpen(true);
            })
            .catch((error) => {
                console.log("error:: ");
                console.log(error);
            });
    };

    const onSubmitSingleXml = (sampleNames) => {
        for (let i = 0; i < sampleNames.length; i++) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            fetch(
                `${selectedKitlot.uri}/toSingleXml?lotname=${selectedKitlot.id}&machineType=${selectedMachine.id}&sampleName=${sampleNames[i]}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            )
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `Result-${sampleNames[i]}-${fileName}.xml`
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((error) => {
                    console.log("error:: ");
                    console.log(error);
                });
        }
        setAlertContent({
            severity: "success",
            message: `Downloading XMLs`,
        });
        setAlertOpen(true);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertOpen(false);
    };

    return (
        <div className="flexbox-page">
            <div>
                <div className="headerDiv">
                    <Typography variant="h1">
                        GA-map
                        <span
                            style={{ fontSize: "25px", verticalAlign: "top" }}
                        >
                            ®
                        </span>{" "}
                        Analyzer
                    </Typography>
                    <Button
                        onClick={reset}
                        variant="contained"
                        className="button"
                    >
                        Restart
                    </Button>
                </div>
                <MachineSelect
                    selectedMachine={selectedMachine}
                    setSelectedMachine={setSelectedMachine}
                    onSubmitMachineSelected={onSubmitMachineSelected}
                    disabled={step != 1}
                />
                {!alert ? (
                    step > 1 &&
                    (kitlots.length == 0 ? (
                        <div className="form-progress">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <FileForm
                                kitlots={kitlots}
                                selectedKitlot={selectedKitlot}
                                setSelectedKitlot={setSelectedKitlot}
                                setSelectedFile={setSelectedFile}
                                fileName={fileName}
                                setFileName={setFileName}
                                onSubmitTableData={onSubmitTableData}
                                disabled={
                                    tableData.length > 0 || qcData.length > 0
                                }
                                helpText={helpText}
                            />
                            {(tableData.length > 0 || qcData.length > 0) && (
                                <ResultTabs
                                    tableData={tableData}
                                    qcData={qcData}
                                    qcMessages={qcMessages}
                                    fullXmlHandle={onSubmitXml}
                                    singleXmlHandle={onSubmitSingleXml}
                                />
                            )}
                        </div>
                    ))
                ) : (
                    <Alert severity="warning">
                        {`No kitlots available ${selectedMachine.id}! Try reloading the page or contact support`}
                    </Alert>
                )}
                <SnackbarAlert
                    open={alertOpen}
                    severity={alertContent.severity}
                    message={alertContent.message}
                    handleClose={handleAlertClose}
                />
            </div>
        </div>
    );
}

export default Home;
