import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./../App.css";
import UploadIcon from "@mui/icons-material/Upload";
import {
    Box,
    Button,
    TextField,
    Alert,
    MenuItem,
    Select,
    Paper,
    CircularProgress,
} from "@mui/material";
import { KitTable } from "../components/KitTable";

function Admin({ token }) {
    const URIs = useMemo(
        () => [
            { name: "API 1", uri: process.env.REACT_APP_API1_URI },
            { name: "API 2", uri: process.env.REACT_APP_API2_URI },
            { name: "API 3", uri: process.env.REACT_APP_API3_URI },
            { name: "API 4", uri: process.env.REACT_APP_API4_URI },
            { name: "API 5", uri: process.env.REACT_APP_API5_URI },
            { name: "API 6", uri: process.env.REACT_APP_API6_URI },
        ],
        []
    );

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState("No file selected");
    const [password, setPassword] = useState();
    const [success, setSuccess] = useState();
    const [warningMessage, setWarningMessage] = useState("");
    const [kits, setKits] = useState([]);
    const [apiUri, setApiUri] = useState(null);

    const fetchKitFromOne = useCallback(
        async (uri) => {
            const response = await fetch(`${uri}/getKitlots`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                return await response.json();
            }
            return [];
        },
        [token]
    );

    const fetchMachineType = useCallback(
        async (uri) => {
            return fetch(`${uri}/getMachine`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    return "";
                }
            });
        },
        [token]
    );

    const fetchKitlots = useCallback(async () => {
        if (token) {
            try {
                let lx200_kits = [];
                let magpix_kits = [];
                let _kits = [];
                for (let i = 0; i < URIs.length; i++) {
                    try {
                        const machine =
                            (await fetchMachineType(URIs[i].uri)) ?? "";
                        const kit = await fetchKitFromOne(URIs[i].uri);
                        _kits = [
                            ..._kits,
                            ...[
                                {
                                    api: URIs[i].name,
                                    machine: machine,
                                    kits: kit,
                                },
                            ],
                        ];
                        if (machine === "Lx200") {
                            lx200_kits = [...lx200_kits, ...kit];
                        } else {
                            magpix_kits = [...magpix_kits, ...kit];
                        }
                    } catch (e) {
                        _kits = [
                            ..._kits,
                            ...[
                                {
                                    api: URIs[i].name,
                                    machine: "Could not reach API",
                                    kits: [""],
                                },
                            ],
                        ];
                        console.log(e);
                    }
                }
                setKits(_kits);

                let warn = "";

                if (lx200_kits.length === 0) {
                    warn =
                        warn + "None of the APIs contain kitlots for Lx200. ";
                } else {
                    if (new Set(lx200_kits).size !== lx200_kits.length) {
                        warn =
                            warn +
                            "Two APIs contain the same kit lot ID for Lx200. ";
                    }
                }
                if (magpix_kits.length === 0) {
                    warn =
                        warn + "None of the APIs contain kitlots for Magpix. ";
                } else {
                    if (new Set(magpix_kits).size !== magpix_kits.length) {
                        warn =
                            warn +
                            "Two APIs contain the same kit lot ID for Magpix. ";
                    }
                }
                setWarningMessage(warn);
            } catch (e) {
                console.log(e);
            }
        }
    }, [fetchMachineType, fetchKitFromOne, URIs, token]);

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setFileName(file.name);
    };

    const handleInput = (e) => {
        setPassword(e.target.value);
    };

    const handleSelect = (e) => {
        setApiUri(e.target.value);
    };

    const onSubmitKitUpdate = () => {
        if (selectedFile && password && apiUri) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            fetch(`${apiUri}/updateKitlot?password=${password}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            })
                .then((response) => {
                    if (response.ok) {
                        setSuccess("success");
                        fetchKitlots();
                    } else {
                        setSuccess("fail");
                    }
                })
                .catch((error) => {
                    console.log("error:: ");
                    console.log(error);
                });
        }
    };

    const deleteKitlot = () => {
        if (password && apiUri) {
            fetch(`${apiUri}/deleteKitlot?password=${password}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    if (response.ok) {
                        setSuccess("success");
                        fetchKitlots();
                    } else {
                        setSuccess("fail");
                    }
                })
                .catch((error) => {
                    console.log("error:: ");
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        fetchKitlots();
    }, [fetchKitlots, token]);

    return (
        <div className="flexbox-page">
            {warningMessage.length > 0 && (
                <Alert severity="warning">{warningMessage}</Alert>
            )}
            {kits.length == 0 ? <CircularProgress /> : <KitTable rows={kits} />}
            <Paper elevation={8}>
                <form className="form">
                    <TextField
                        required
                        id="password"
                        label="Admin password"
                        onChange={handleInput}
                    />
                    <Select
                        id="api_select"
                        value={apiUri}
                        label="API"
                        onChange={handleSelect}
                    >
                        {URIs.map((uri) => {
                            return (
                                <MenuItem value={uri.uri} key={uri.name}>
                                    {uri.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <div className="fileupload">
                        <Box
                            component="div"
                            sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                color: (theme) => theme.palette.ga.gaText,
                            }}
                        >
                            {fileName}
                        </Box>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<UploadIcon />}
                            className="button"
                        >
                            Upload file
                            <input
                                type="file"
                                accept=".bin"
                                onChange={handleFileInput}
                                onClick={(e) => (e.target.value = null)}
                                hidden
                            />
                        </Button>
                    </div>
                </form>
                <Button
                    onClick={onSubmitKitUpdate}
                    variant="contained"
                    sx={{ margin: "15px 0" }}
                    className="button"
                >
                    Update
                </Button>
                <Button
                    onClick={deleteKitlot}
                    variant="contained"
                    sx={{ margin: "15px 0" }}
                    className="button"
                    color="error"
                >
                    Delete
                </Button>
                {success === "success" && (
                    <Alert severity="success">Success!</Alert>
                )}
                {success === "fail" && <Alert severity="error">Fail!</Alert>}
            </Paper>
        </div>
    );
}

export { Admin };
