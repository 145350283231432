import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import logo from "./../images/header_img.png";
import "../style.css";
import { StyledAppBar } from "../theme/StyledAppBar";

function HeaderBar() {
    const navItems = ["Contact", "Profile"];
    const hrefs = ["/contact", "/profile"];

    return (
        <Box sx={{ display: "flex" }}>
            <StyledAppBar component="nav" className="appbar" position="sticky">
                <Toolbar className="appbar-toolbar">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="appbar-img" />
                    </Link>
                    <Box sx={{ display: "flex" }}>
                        {navItems.map((item, index) => (
                            <Link
                                to={hrefs[index]}
                                key={item}
                                className="appbar-menu-button"
                            >
                                <Typography>{item}</Typography>
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
            </StyledAppBar>
        </Box>
    );
}

export { HeaderBar };
