import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

function KitTable({ rows }) {
    return (
        <Paper elevation={8} sx={{ margin: "25px 0" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography>API</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Machine</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>Kitlots</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        return (
                            <TableRow
                                key={row.api}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell sx={{ width: 100 }}>
                                    {row.api}
                                </TableCell>
                                <TableCell sx={{ width: 100 }}>
                                    {row.machine}
                                </TableCell>
                                <TableCell sx={{ width: 200 }}>
                                    {row.kits.join(", ")}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}

export { KitTable };
