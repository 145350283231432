import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate((appState && appState.returnTo) || window.location.pathname);
    };
    return (
        <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
            {children}
        </Auth0Provider>
    );
};

const ConditionalApp = () => {
    const { isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return (
            <div>
                An error occured when loading the page, please contact support{" "}
                {error.message}
            </div>
        );
    }
    if (isAuthenticated) {
        return <App />;
    } else {
        return loginWithRedirect();
    }
};

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUDIENCE;

const root = createRoot(document.getElementById("root"));
root.render(
    <Router>
        <Suspense fallback="...is loading">
            <Auth0ProviderWithRedirectCallback
                domain={domain}
                clientId={clientId}
                redirectUri={window.location.origin}
                audience={audience}
                useRefreshTokens={true}
                scope="read:all"
            >
                <ConditionalApp />
            </Auth0ProviderWithRedirectCallback>
        </Suspense>
    </Router>
);
