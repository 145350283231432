import React from "react";
import { DropdownPicker } from "./DropdownPicker";
import { Button, Typography } from "@mui/material";
import "../style.css";

function MachineSelect({
    selectedMachine,
    setSelectedMachine,
    onSubmitMachineSelected,
    disabled,
}) {
    const options = [
        { label: "Luminex® 200™", id: "Lx200" },
        { label: "MAGPIX®", id: "Magpix" },
    ];

    const handleMachinePick = (e, value) => {
        setSelectedMachine(value);
    };

    return (
        <div>
            <form className="form">
                <Typography className="basicpadding">
                    Select Luminex® detection platform
                </Typography>
                <DropdownPicker
                    value={selectedMachine}
                    options={options}
                    handleChange={handleMachinePick}
                    disabled={disabled}
                    label="Platforms"
                />
            </form>
            <Button
                onClick={onSubmitMachineSelected}
                variant="contained"
                sx={{ margin: "15px 0" }}
                className="button"
                disabled={disabled}
            >
                Next
            </Button>
        </div>
    );
}

export { MachineSelect };
