import React from "react";
import { Paper, Box, Typography, Link } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "./../App.css";
import "../style.css";

function Contact() {
    return (
        <div className="flexbox-page">
            <div className="contact-holder">
                <Paper elevation={8} className="profile-paper">
                    <Box className="contact-box">
                        <EmailOutlinedIcon
                            sx={{ fontSize: 55 }}
                            color="primary"
                        />
                        <Typography
                            variant="h3"
                            component="h1"
                            sx={{ margin: "15px 0 0" }}
                        >
                            Contact Genetic Analysis
                        </Typography>
                        <Typography variant="body1" sx={{ margin: "15px 0" }}>
                            <i>Email: support@genetic-analysis.com</i>
                        </Typography>
                    </Box>
                </Paper>
                <Paper elevation={8} className="profile-paper">
                    <Box className="contact-box">
                        <DescriptionOutlinedIcon
                            sx={{ fontSize: 55 }}
                            color="primary"
                        />
                        <Typography
                            variant="h3"
                            component="h1"
                            sx={{ margin: "15px 0 0" }}
                        >
                            Documentation
                        </Typography>
                        <Link
                            href="https://ga-map.com/resources/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="link-div">
                                <OpenInNewOutlinedIcon
                                    sx={{ margin: "0 5px" }}
                                    color="primary"
                                />
                                <Typography color="icon">
                                    Find the GA-map® Analyzer User Manual here
                                </Typography>
                            </div>
                        </Link>
                    </Box>
                </Paper>
            </div>
        </div>
    );
}

export { Contact };
